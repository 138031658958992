
import scroll from './scroll';
import glightbox from './glightbox';
import animations from "./animations";

scroll();
glightbox();
animations();

/**
 * Randomly set the hero images
 */
function setHeroImage() {

    // const heroImages = [];
    // const hero = document.querySelector('.hero .bg')
    //
    // hero.src =
    //     'https://res.cloudinary.com/rdl/image/upload/c_scale,f_auto,w_1200/v1707550787/rcbb/images/' +
    //     heroImages[Math.floor(Math.random() * heroImages.length)];
}
//setHeroImage();
